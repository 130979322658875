import { ButtonGroup, Grid, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink } from 'react-router-dom';
import '../App.css';
import logo from '../images/logo.png';

const Navbar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction='column' alignItems='center' sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Grid item>
          <Box
            component='img'
            sx={{
              backgroundColor: 'white',
              height: 128,
            }}
            src={logo}
            alt='logo'
          />
        </Grid>
        <Grid item>
          <Typography variant='h6'>LEGACY | INNOVATION | ADVENTURE</Typography>
        </Grid>
      </Grid>
      <AppBar position='static'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <ButtonGroup>
              <Button component={RouterLink} to={'/'} sx={{ my: 2, color: 'white', display: 'block' }}>
                Home
              </Button>
              <Button component={RouterLink} to={'/map'} sx={{ my: 2, color: 'white', display: 'block' }}>
                Map
              </Button>
              <Button component={RouterLink} to={'/about'} sx={{ my: 2, color: 'white', display: 'block' }}>
                About
              </Button>
              <Button component={RouterLink} to={'/contact'} sx={{ my: 2, color: 'white', display: 'block' }}>
                Contact
              </Button>
            </ButtonGroup>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
