import Grid from '@mui/material/Grid';
import React from 'react';
import '../App.css';
import PropertyCard from '../components/PropertyCard';
import { properties } from '../lib/properties';

const Home = () => {
  return (
    <Grid container direction='row' justifyContent='space-evenly' alignItems='stretch'>
      {properties.map((p) => {
        return <PropertyCard key={p.id} property={p} />;
      })}
    </Grid>
  );
};

export default Home;
