import { PropertyType } from '../interfaces';

export const properties: PropertyType[] = [
  {
    id: '3413',
    title: '3413',
    teaser: 'Downtown Paradise 🏝 Heated Pool/Spa! #LOCATION 🌇🌄',
    city: 'Downtown Phoenix',
    latlng: [33.487787, -112.095757],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=46340847',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-downtown1',
    vrbo_url: 'https://www.vrbo.com/2116255?unitId=2680789',
    description_short:
      'Unwind by the beautiful pebble-tech saltwater pool & hot tub with soothing water feature. Your unobstructed view of only desert beyond the backyard will make you feel like you are miles from nowhere. ',
    description_long:
      'Heart of Phoenix, close to dozens of our almost 200 Golf courses! Take a relaxing bike ride to Encanto Park, the Light Rail, dozens of restaurants & shops, downtown, Melrose, and Uptown! This beautiful home has it all! Kick back in the hottub after a long day on the course, or cool off with a dip in the heated saltwater pool. Enjoy a BBQ with friends around the fire. Let the kids play on the backyard playground. Then, sit back & relax with a movie at the end of the day on the 120" movie screen!',
    notes:
      "Beautiful Mid-Century Modern home near downtown & LOTS of golf! Lay by the pool on a hot summer day; cuddle by the fire on one of Phoenix's beautiful cool winter nights. Hiking, Biking, Eating, Drinking - this location has it all just minutes away. An entertainers kitchen/dining room; a great place to relax and feel at home, even thousands of miles away.",
    numGuests: 14,
    numBedrooms: 4,
    numBeds: 5,
    numBaths: 3.5,
    numPics: 34,
  },
  {
    id: 'portland',
    title: 'Portland',
    teaser: '✨ Historic Downtown ✨ Pool/Outdoor Kitchen/#LOCATION',
    city: 'Downtown Phoenix',
    latlng: [33.459756650684476, -112.08135355156978],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=49815963',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-downtown2',
    vrbo_url: 'https://www.vrbo.com/2340970?unitId=2907328',
    description_short:
      'Hidden away on the most iconic street in Downtown Phoenix! Our home is walking distance to Roosevelt Row, restaurants, bars, and shopping! This beautiful historic home is thoughtfully decorated and furnished to give you a relaxing and enjoyable stay.',
    description_long:
      "Nestled away on the most iconic street in Downtown Phoenix, our home is walking distance to Roosevelt Row, restaurants, bars, and shopping! This beautiful historic home is thoughtfully decorated and furnished to give you a relaxing and enjoyable stay. The backyard offers a dipping pool, an outdoor kitchen, and a double pergola under shade trees - a backyard oasis. The Master Suite has a king bed and private bath. We've included all of the amenities for a wonderful & enjoyable stay",
    notes:
      'Beautiful 100+ yr old house IN downtown Phoenix. This house has a master bedroom with private bathroom; the 2nd & 3rd large bedrooms each have a queen bed, sitting area, & closet; the 4th bedroom-type space serves as both an office with desk & chair, as well as an extra guest room. It includes a Full-size fouton, a queen air mattress, and a Graco pack-and-play crib for the little ones. It adjoins the master in the hallway and also shares a Jack & Jill bathroom with bedroom 3.',
    numGuests: 6,
    numBedrooms: 3,
    numBeds: 4,
    numBaths: 3,
    numPics: 28,
  },
  {
    id: 'goodyear',
    title: 'Goodyear',
    teaser: '🏜 Desert Oasis 🏜 Heated Pool/Spa ✨Unrivaled Desert View',
    city: 'Goodyear',
    latlng: [33.36131630499013, -112.43548980589598],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=50049144',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-goodyear1',
    vrbo_url: 'https://www.vrbo.com/2340909?unitId=2907267',
    description_short:
      'Unwind by the beautiful pebble-tech saltwater pool & hot tub with soothing water feature. Your unobstructed view of only desert beyond the backyard will make you feel like you are miles from nowhere.',
    description_long:
      'Welcome to our beautiful Desert Oasis home! Unwind by the beautiful pebble-tech pool with hot tub and water feature. Your unobstructed view of only desert beyond the backyard will make you feel like you are miles from nowhere. We sanitize the house entirely after every guest and pride ourselves in a comfortable, clean, and safe environment for you and your family to enjoy. Nearby lakes & golf course provide endless entertainment. Come see this diamond in the desert for yourself!',
    notes:
      'Our beautiful, comfortable, & clean home features a master bedroom with en suite bathroom, tub, shower, and water closet. The master features a new king memory foam mattress. The second and third bedrooms each have a new memory-foam queen bed and a closet. The fourth bedroom doubles as an office and features a Full size futon, queen air mattress, and Graco pack-and-play for the little ones.',
    numGuests: 8,
    numBedrooms: 4,
    numBeds: 4,
    numBaths: 2,
    numPics: 13,
  },
  {
    id: 'palmcroft',
    title: 'Palmcroft',
    teaser: '🖤 of Central Phx 💎🌇 Cozy 4 BR, Walk to Encanto Park!',
    city: 'Downtown Phoenix',
    latlng: [33.46938113732791, -112.09094756725096],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=52289217',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-downtown3',
    vrbo_url: 'https://www.vrbo.com/2548173?unitId=3118269',
    description_short:
      'Lovely 4-bedroom home in the most iconic neighborhood in central Phoenix - Palmcroft/Encanto. Our house is fully furnished and has all the amenities of home to provide you with a comfortable and relaxing stay.',
    description_long:
      'Quaint 4 bedroom home in one of the most iconic neighborhoods in central Phoenix - Palmcroft/Encanto. Situated just a block from Encanto Park and a short walk to Enchanted Island. Our house is fully furnished and has all the amenities of home to provide you with a comfortable and relaxing stay. 4 Flatscreen TVs! In-suite bathroom for 2 Master bedrooms. Every bedroom has a new & comfy memory foam mattress. The kitchen has all required utensils, service for 8, and equipment. Come and see!',
    notes: 'This spacious downtown 4bd has plenty of room for the whole family. 2 master suites with their own bathroom!',
    numGuests: 8,
    numBedrooms: 4,
    numBeds: 4,
    numBaths: 3,
    numPics: 13,
  },
  {
    id: 'palmcroft_casita',
    title: 'Palmcroft Casita',
    teaser: 'Cute & Comfy Studio Casita w/ Laundry & Kitchen',
    city: 'Downtown Phoenix',
    latlng: [33.46938113732791, -112.09094756725096],
    airbnb_url: 'https://airbnb.com/h/life-better-phx-downtown3-1',
    vrbo_url: null,
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=611472790409982179',
    description_short: 'Our guest house is fully furnished and has all the amenities of home to provide you with a comfortable and relaxing stay.',
    description_long:
      'Quiet studio Casita in one of the most iconic neighborhoods in central Phoenix - Palmcroft/Encanto. Situated just a block from Encanto Park and a short walk to Enchanted Island. Our guest house is fully furnished and has all the amenities of home to provide you with a comfortable and relaxing stay. Flatscreen TV, private bathroom, kitchen, & laundry. New & comfy memory foam mattress. The kitchen has all required utensils, & service items.  Can be booked with the main house. Come and see!',
    notes: null,
    numGuests: 2,
    numBedrooms: 1,
    numBeds: 1,
    numBaths: 1,
    numPics: 10,
  },
  {
    id: 'coronado',
    title: 'Coronado',
    teaser: 'Uptown Coronado Retreat w/ Pool 🏝 Fire Pit 🔥 Casita 🌵',
    city: 'Downtown Phoenix',
    latlng: [33.47483488987374, -112.0498595807465],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=560403285560328841',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-downtown4',
    vrbo_url: 'https://www.vrbo.com/2685402?unitId=3256139',
    description_short:
      'Escape to downtown Phoenix in this stylish, mid-century modern sanctuary! Nestled in the historic Coronado district; the main house has 2 bedrooms, living room, kitchen, & bath. The detached casita is a king suite.',
    description_long:
      "Escape to downtown Phoenix in this stylish, mid-century modern sanctuary. Nestled in the historic Coronado district, the main house has 2 bedrooms, living room, kitchen, & bath. Casita has king bed, private bath & full kitchen. Our beautiful-peaceful backyard has everything you need for a calm & centered refuge. Splash in the pool, sun yourself on the patio, bbq w/friends & sit by the fire at night. Easy access to downtown, we're 5mins from the 5th largest city in America & all it has to offer!",
    notes: null,
    numGuests: 6,
    numBedrooms: 3,
    numBeds: 3,
    numBaths: 2,
    numPics: 30,
  },
  {
    id: 'juniper',
    title: 'Juniper',
    teaser: 'Peaceful 3 BR Condo 🌆 King Bed/Parking/ASU/DT/Tempe',
    city: 'Tempe',
    latlng: [33.392070533434286, -111.91298278770117],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=53786037',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-tempe1',
    vrbo_url: 'https://www.vrbo.com/2579639?unitId=3149955',
    description_short:
      'Come relax and see all that Tempe Arizona has to offer! Enjoy a day at the pool, or do some shopping in downtown. Our condo has everything you need to feel right at home!',
    description_long:
      'Wake up to the lovely green courtyard and aroma of fresh coffee filling the air. ☕️ Take a walk through the community and read a book on the bench. We’d love to have you stay at our beautiful home and enjoy the HD Flatscreen Roku TV w/ streaming, luxurious community pool, free WiFi, King bed, open kitchen w/ brand new appliances, 2 covered parking spots, nearby local attractions, and a stocked kitchen for cooking',
    notes:
      'Newly remodeled with an amazing staircase. All appliances have been updated. 2 queen beds and 1 king for the master w/ a beautiful makeup vanity.',
    numGuests: 6,
    numBedrooms: 3,
    numBeds: 3,
    numBaths: 2,
    numPics: 26,
  },
  {
    id: 'surprise',
    title: 'Surprise',
    teaser: 'Spacious & Beautiful Private Home w/ Pool',
    city: 'Surprise',
    latlng: [33.63089882465583, -112.42000948996174],
    guesty_url: 'https://lifebetter.staydirectly.com/listing?a=621038418255735413',
    airbnb_url: 'https://airbnb.com/h/life-better-phx-surprise1',
    vrbo_url: 'https://www.vrbo.com/2847842?unitId=3419875',
    description_short:
      "Our beautiful home is in a lovely suburban neighborhood. Forget your worries as you sit by the pool or fire pit to enjoy some delicious s'mores. This home has everything you need when visiting the beautiful city of Surprise.",
    description_long:
      "Our beautiful home is in a lovely suburban neighborhood. Forget your worries as you sit by the pool or fire pit to enjoy some delicious s'mores. This home has everything you need when visiting the beautiful city of Surprise.",
    notes: null,
    numGuests: 6,
    numBedrooms: 3,
    numBeds: 3,
    numBaths: 2.5,
    numPics: 26,
  },
  {
    id: 'mesa',
    title: 'Mesa',
    teaser: '⛳️ Golf Getaway 🏌️‍♀️ Relaxing Condo, Quiet 45+ Community🌄',
    city: 'Mesa',
    latlng: [33.37686425192815, -111.58968548627912],
    guesty_url: null,
    airbnb_url: 'https://airbnb.com/h/life-better-phx-mesa1',
    vrbo_url: 'https://www.vrbo.com/2757677?unitId=3329577',
    description_short:
      'Charming 2 bed/2 bath golf condo w/cozy front porch & golf course views. Comfortable Master bedroom w/private bath,dual vanity, walk-in closet. Great neighborhood & vibe.',
    description_long:
      "Charming 2 bed/2 bath golf condo w/cozy front porch & golf course views. Enter to discover a welcoming hallway that leads you to the spacious great room w/arched doorway, 9' ceilings, recessed lighting, tile flooring w/carpet in all the right places, & back patio door. Spotless kitchen features ample cabinets w/crown molding, new granite counters, built-in appliances, & a peninsula w/breakfast bar. Comfortable Master bedroom w/private bath,dual vanity, walk-in closet. Great neighborhood & vibe.",
    notes: null,
    numGuests: 4,
    numBedrooms: 2,
    numBeds: 2,
    numBaths: 2,
    numPics: 22,
  },
];
