import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const About = () => {
  return (
    <Grid container direction='column' alignItems='center' spacing={2}>
      <Grid item sx={{ mt: 2 }}>
        <img style={{ width: 300, height: 300, borderRadius: 20, border: '5px solid #000' }} src={require('../images/shawn.jpg')} alt='about' />
      </Grid>
      <Grid item>
        <Box sx={{ p: 3, maxWidth: 500 }}>
          <Typography align='center' variant='h4' gutterBottom>
            About Us
          </Typography>
          <Typography paragraph variant='body1' gutterBottom>
            I am an adventurer who loves life. I have 4 beautiful children and an amazing wife. Together we have visited over 50 countries and all 50
            states in the US. I hope to reflect our passion for travel in the homes that we host. We have stayed in short-term rental properties from
            Seoul to London, L.A. to Paris, New Delhi to New York, and everywhere in between; and we try to take the best of everything we’ve seen and
            experienced in the world, and deliver it to you in Phoenix, AZ.
          </Typography>
          <Typography paragraph variant='body1' gutterBottom>
            Our company, Life.Better.LLC, started in 2018 after we moved back from an overseas military posting to the U.S. We had been stationed
            overseas for over 4 years, before being assigned back to the states — to Phoenix, AZ. So, we packed up our things and our 4 small children
            (ages 6, 6, 4, 2) and relocated to our new city. We are not from “the valley” and so we had no network of family or friends, no
            infrastructure in place for our family, and literally no place to live. We bounced around short-term rental properties for 2 months until
            we found our home; while juggling work, school, & life with only 1 car. We were living out of suitcases, taking Uber to work & going
            through a very stressful phase of life — and we knew that there had to be a way to do Life, Better.
          </Typography>
          <Typography paragraph variant='body1' gutterBottom>
            Since then, we have grown our business from a single rental home to a network of furnished rentals across the valley and have even
            included a few rental cars. We understand what it is like to need housing, transportation, and support. And, we strive to take the stress
            out of these situations for all of our guests.
          </Typography>
          <Typography paragraph variant='body1' gutterBottom>
            Whether on military temporary duty or permanent change of station, traveling nurses, renovating a home and needing temporary lodging,
            displaced because of damage and repairs to your primary home, escaping the cold winters to sunny Phoenix, meeting old friends for a golf
            weekend, or just wanting to get away for a minute: Life.Better. has you covered. We have been in your shoes and are eager to make your
            experience an amazing one!
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default About;
