import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Contact = () => {
  return (
    <Grid container direction='column' alignItems='center' spacing={2}>
      <Grid item>
        <Typography align='center' variant='h4' gutterBottom>
          Contact Us
        </Typography>
        <Button variant='contained' startIcon={<EmailIcon />} href={`mailto:Info@LifeBetter.us`} target='_blank' rel='noopener noreferrer'>
          Info@LifeBetter.us
        </Button>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Contact;
