import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PropertyType } from '../interfaces';

const PropertyCard: FunctionComponent<{ property: PropertyType }> = ({ property }) => {
  return (
    <Card sx={{ width: 345, p: 1, m: 2, display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        title={`${property.title}`}
        titleTypographyProps={{ align: 'center', variant: 'button' }}
        subheader={`${property.teaser}`}
        subheaderTypographyProps={{ align: 'center', variant: 'body2' }}
        sx={{ mb: 1, p: 0 }}
      />
      <RouterLink to={`/property/${property.id}`}>
        <CardMedia component='img' height='194' src={require(`../images/cards/${property.id}.jpg`)} alt='card_photo' />
      </RouterLink>
      <CardContent>
        <Typography align='center' variant='overline' display='block' gutterBottom>
          {property.city}
        </Typography>
        <Typography variant='overline' display='block' gutterBottom>
          <b>Guests:</b> {property.numGuests}, <b>Bedrooms:</b> {property.numBedrooms}, <b>Beds:</b> {property.numBeds}, <b>Baths: </b>
          {property.numBaths}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {property.description_short}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ mt: 'auto' }}>
        <Grid container direction='column' alignItems='center' spacing={2}>
          <Grid item>
            {
              <Button color='secondary' variant='contained' component={RouterLink} to={`/property/${property.id}`}>
                Details & Pics
              </Button>
            }
          </Grid>
          <Grid item>
            <ButtonGroup variant='contained' aria-label='outlined button group'>
              {property.guesty_url && (
                <Button href={`${property.guesty_url}`} target='_blank' rel='noopener noreferrer'>
                  Book Direct
                </Button>
              )}
              {property.airbnb_url && (
                <Button href={`${property.airbnb_url}`} target='_blank' rel='noopener noreferrer'>
                  AirBnB
                </Button>
              )}
              {property.vrbo_url && (
                <Button href={`${property.vrbo_url}`} target='_blank' rel='noopener noreferrer'>
                  VRBO
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default PropertyCard;
