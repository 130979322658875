import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/about';
import Contact from './pages/contact';
import Home from './pages/home';
import LeafletMap from './pages/map';
import Property from './pages/property';

const App = () => {
  const HeaderLayout = () => (
    <>
      <header>
        <Navbar />
      </header>
      <Outlet />
    </>
  );

  return (
    <BrowserRouter>
      {/* ensures page is always scrolled to the top on any route change */}
      <ScrollToTop />
      <Routes>
        <Route element={<HeaderLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/property/:propertyId' element={<Property />} />
          <Route path='/map' element={<LeafletMap />} />
          <Route path='*' element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
