import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { Circle, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { properties } from '../lib/properties';
import { LeafletMouseEvent } from 'leaflet';
import { Link as RouterLink } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const LeafletMap = () => {
  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletMouseEvent) {
        e.target.openPopup();
      },
      mouseover(e: LeafletMouseEvent) {
        e.target.openPopup();
      },
      mouseout(e: LeafletMouseEvent) {
        //  e.target.closePopup();
      },
    }),
    []
  );

  return (
    <Box display='flex' flexDirection='column' height='100vh'>
      <Box height='100%'>
        <MapContainer
          center={[33.46849618479996, -112.03795178083044]}
          zoom={11}
          style={{
            backgroundColor: 'white',
            height: 'calc(100% - 64px)',
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <TileLayer
            url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            attribution='Map data ©2022 Google'
          />
          {properties.map((property) => {
            return (
              <React.Fragment key={property.id}>
                <Marker position={property.latlng} eventHandlers={eventHandlers}>
                  <Circle center={property.latlng} radius={500} pathOptions={{ color: 'blue' }} />
                  <Popup>
                    <Typography align='center' variant='button' display='block' gutterBottom>
                      {property.title}
                    </Typography>
                    <img src={require(`../images/cards/${property.id}.jpg`)} style={{ height: 200, width: 300 }} alt='' />
                    <Grid container direction='column' alignItems='center' spacing={2}>
                      <Grid item></Grid>
                      <Grid item sx={{ mt: 0 }}>
                        {
                          <Button color='primary' variant='outlined' component={RouterLink} to={`/property/${property.id}`}>
                            Details & Pics
                          </Button>
                        }
                      </Grid>
                      <Grid item sx={{ mt: 0 }}>
                        <ButtonGroup variant='outlined' aria-label='outlined button group'>
                          {property.guesty_url && (
                            <Button href={`${property.guesty_url}`} target='_blank' rel='noopener noreferrer'>
                              Book Direct
                            </Button>
                          )}
                          {property.airbnb_url && (
                            <Button href={`${property.airbnb_url}`} target='_blank' rel='noopener noreferrer'>
                              AirBnB
                            </Button>
                          )}
                          {property.vrbo_url && (
                            <Button href={`${property.vrbo_url}`} target='_blank' rel='noopener noreferrer'>
                              VRBO
                            </Button>
                          )}
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          })}
        </MapContainer>
      </Box>
    </Box>
  );
};

export default LeafletMap;
