import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import L from 'leaflet';
import { Circle, MapContainer, Marker, TileLayer } from 'react-leaflet';
import Gallery from 'react-photo-gallery';
import { Navigate, useParams } from 'react-router-dom';
import { PropertyType } from '../interfaces';
import { properties } from '../lib/properties';

const Property = () => {
  let params = useParams();

  const property: PropertyType | undefined = properties.find((p) => p.id === params.propertyId);

  // if we don't find a matching property, redirect to the home page
  if (!property) {
    return <Navigate to='/' replace={true} />;
  }

  const photos = Array.from({ length: property.numPics }, (_, i) => i).map((i) => {
    return {
      src: require(`../images/galleries/${property.id}/${property.id}_${i + 1}.jpg`),
      width: 3,
      height: 2,
    };
  });

  return (
    <Grid container direction='column' spacing={2}>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={2} sx={{ mt: 2 }}>
        <Grid item>
          <Card sx={{ minWidth: 275, maxWidth: 450, height: '100%', ml: 4, mr: 2 }}>
            <CardContent>
              <Typography align='center' variant='h5' gutterBottom>
                {property.title}
              </Typography>
              <Typography align='center' variant='body2' gutterBottom>
                {property.teaser}
              </Typography>
              <Divider />
              <Typography align='center' variant='body1' display='block' gutterBottom>
                <b>Guests:</b> {property.numGuests}, <b>Bedrooms:</b> {property.numBedrooms}, <b>Beds:</b> {property.numBeds}, <b>Baths:</b>{' '}
                {property.numBaths}
              </Typography>
              <Divider />
              <Typography variant='body1' color='text.secondary'>
                {property.description_long}
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container direction='row' justifyContent='center' spacing={2}>
                <Grid item sx={{ mt: 1 }}>
                  <ButtonGroup size='large' variant='contained' aria-label='outlined button group'>
                    {property.guesty_url && (
                      <Button href={`${property.guesty_url}`} target='_blank' rel='noopener noreferrer'>
                        Book DIrect
                      </Button>
                    )}
                    {property.airbnb_url && (
                      <Button href={`${property.airbnb_url}`} target='_blank' rel='noopener noreferrer'>
                        AirBnB
                      </Button>
                    )}
                    {property.vrbo_url && (
                      <Button href={`${property.vrbo_url}`} target='_blank' rel='noopener noreferrer'>
                        VRBO
                      </Button>
                    )}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item>
          <Box sx={L.Browser.mobile ? { width: 350, height: 350 } : { width: 720, height: 480 }}>
            <MapContainer
              center={property.latlng}
              zoom={13}
              style={{
                backgroundColor: 'white',
                height: 'calc(100%)',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <TileLayer
                url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                attribution='Map data ©2022 Google'
              />
              <Marker position={property.latlng}>
                <Circle center={property.latlng} radius={500} pathOptions={{ color: 'blue' }} />
              </Marker>
            </MapContainer>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Gallery photos={photos} />;
      </Grid>
    </Grid>
  );
};

export default Property;
